import { dispatchTripAction } from 'context/TripContext/dispatchTrip/dispatchTrip.action'
import { editInputAction } from 'context/TripContext/editInput/editInput.action'
import { editInputReducer } from 'context/TripContext/editInput/editInput.reducer'
import { findOneDriverAction } from 'context/TripContext/findOneDriver/findOneDriver.action'
import { getTripAction } from 'context/TripContext/getTrip/getTrip.action'
import { setTripReducer } from 'context/TripContext/getTrip/setTrip.reducer'
import { defaultTripState } from 'context/TripContext/TripContext'
import { useReducer } from 'react'
import { doReducer } from 'services/reducer/reducer'

export const tripActions = {
    getTrip: getTripAction,
    editInput: editInputAction,
    dispatchTrip: dispatchTripAction,
    findOneDriver: findOneDriverAction,
}

export const TripReducerTypes = {
    setTrip: {
        key: 'SET_TRIP',
        reducer: setTripReducer,
    },
    updateInput: {
        key: 'UPDATE_INPUT',
        reducer: editInputReducer,
    },
}

export const TripReducer = (state, payload) =>
    doReducer(state, payload, TripReducerTypes)

export const useTripReducer = initState => {
    const [state, dispatch] = useReducer(TripReducer, {
        ...defaultTripState,
        ...(initState || {}),
    })

    return { state, dispatch }
}
