import classNames from 'classnames'
import { Input as ChakraInput } from '@chakra-ui/react'

export const Input = ({
    label,
    placeholder,
    id,
    className,
    containerClassName,
    inputRef,
    onChange,
    onChangeText,
    ...props
}) => {
    const change = e => {
        onChangeText && onChangeText(e.target.value)
        onChange && onChange(e)
    }

    return (
        <div className={classNames(containerClassName || '')}>
            <label htmlFor={id} className="text-slate-800 text-sm font-bold">
                {label}
            </label>
            <div className="bg-white">
                <ChakraInput
                    id={id}
                    ref={inputRef}
                    onChange={change}
                    focusBorderColor="teal.500"
                    placeholder={placeholder || label}
                    {...props}
                />
            </div>
        </div>
    )
}
