import {
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useBreakpointValue,
    useToast,
} from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { userRole } from 'consts/userRole'
import dayjs from 'dayjs'
import { useUserRole } from 'hooks/useUserRole'
import { showToast, toastStatus } from 'libs/toast/toast'
import { useEffect } from 'react'
import { useState } from 'react'
import { DispatchModalForm } from 'screens/Trip/DispatchModalForm'
import { EmddiService } from 'services/EmddiService/EmddiService'

export const TripInfoModal = ({ trip, close }) => {
    const size = useBreakpointValue({
        base: 'full',
        md: 'xl',
    })
    const [loading, setLoading] = useState(false)
    const role = useUserRole()

    const [form, setForm] = useState({
        phone: '',
        name: '',
        licensePlate: '',
        manufacturer: '',
        model: '',
        color: '',
    })
    const [originalFare, setOriginalFare] = useState(0)
    const changeForm = update => setForm(f => ({ ...f, ...update }))

    const actionOriginlFare = { originalFare, setOriginalFare }
    useEffect(() => {
        setOriginalFare(trip?.originalFare)
    }, [trip])

    const toast = useToast()

    const submit = async () => {
        if (loading) return

        setLoading(true)
        const { success, message } = await EmddiService.dispatchTrip({
            tripId: trip?.id,
            driver: form,
            originalFare,
        })
        setLoading(false)

        if (!success) return showToast(toast, { title: message })

        showToast(toast, {
            status: toastStatus.success,
            title: 'Nhận chuyến thành công',
        })
        window.location.reload()
    }

    const clickSave = () => submit()

    const getPlaceText = text => {
        if (text?.airport?.id) return text?.name

        return `${text?.name} - ${text?.address}`
    }

    const renderInfo = ({ text, value }, i) => (
        <div key={i} className="w-full flex flex-wrap py-1 hover:bg-slate-200">
            <div className="w-1/3">{text}</div>
            <div className="w-2/3 flex">
                <Divider orientation="vertical" />
                <div className="ml-2">{value}</div>
            </div>
        </div>
    )

    const hideExternalAdmin = role === userRole.externalAdmin

    return (
        <Modal size={size} isOpen={!!trip?.id} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Thông tin chuyến</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <div className="flex flex-wrap">
                        {[
                            {
                                text: 'Khách hàng',
                                value: trip?.name,
                            },
                            {
                                text: 'SĐT khách',
                                value: (trip?.phone || '').replace('+84', '0'),
                            },
                            {
                                text: 'Dịch vụ',
                                value: trip?.service?.name,
                            },
                            { text: 'Tài xế', value: trip?.driver?.name },
                            {
                                text: 'Số điện thoại',
                                value: (trip?.driver?.phone || '').replace(
                                    '+84',
                                    '0'
                                ),
                            },
                            {
                                text: 'Xe',
                                value: trip?.vehicle?.id
                                    ? trip?.vehicle?.licensePlate +
                                          ' - ' +
                                          trip?.vehicle?.manufacturer ||
                                      trip?.vehicle?.model
                                    : '',
                            },
                            {
                                text: 'Đón khách',
                                value: dayjs(trip?.pickupTime).format(
                                    'HH:mm DD/MM/YYYY'
                                ),
                            },
                            {
                                text: 'Điểm đón',
                                value: getPlaceText(trip?.waypoints?.[0]),
                            },
                            {
                                text: 'Điểm trả',
                                value: getPlaceText(trip?.waypoints?.[1]),
                            },
                            {
                                text: 'Khách trả',
                                value: Number(
                                    trip?.price - trip?.discount
                                ).toLocaleString('vi-vn'),
                            },
                            {
                                text: 'Ghi chú',
                                value: trip?.note,
                            },
                        ].map(renderInfo)}
                    </div>

                    {!hideExternalAdmin && (
                        <div className="mt-8">
                            <div>Sửa thông tin tài xế</div>
                            <DispatchModalForm
                                form={form}
                                setForm={changeForm}
                                actionOriginlFare={actionOriginlFare}
                            />
                            <div className="mt-4"></div>
                            <Button onClick={clickSave} disabled={!!loading}>
                                {!loading ? 'Lưu' : 'Đang lưu ...'}
                            </Button>
                        </div>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
