import { Divider, Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { useIsLogin } from 'hooks/useIsLogin'
import { Link } from 'react-router-dom'
import {
    MdOutlineHistoryToggleOff,
    MdOutlineMoney,
    MdOutlineTaxiAlert,
} from 'react-icons/md'
import classNames from 'classnames'

const guestLinkUrls = [
    {
        url: '/',
        text: 'Đăng nhập',
    },
]

const adminLinkUrls = [
    {
        url: '/trip/available',
        text: 'Danh sách chuyến',
        icon: <MdOutlineTaxiAlert />,
    },
    {
        url: '/trip/dispatched',
        text: 'Chuyến đã nhận',
        icon: <MdOutlineHistoryToggleOff />,
    },
    {
        divider: true,
    },
    {
        url: '/service',
        text: 'Dịch vụ',
        icon: <MdOutlineMoney />,
    },
]

export const SideBar = ({ sideBarOpen, setSideBarOpen }) => {
    const isLogin = useIsLogin()

    const closeSideBar = () => setSideBarOpen(false)

    const renderLink = (link, i) =>
        link.divider ? (
            <Divider key={i} />
        ) : (
            <Link to={link.url} key={i} onClick={() => setSideBarOpen(false)}>
                <div className="px-2 py-4 hover:bg-app-500 hover:text-white text-app-700 transition-all flex items-center">
                    {link.icon}
                    <span
                        className={classNames(
                            { 'ml-1': !!link.icon },
                            'text-xs font-bold'
                        )}
                    >
                        {link.text}
                    </span>
                </div>
            </Link>
        )

    const renderLinks = (isLogin ? adminLinkUrls : guestLinkUrls).map(
        renderLink
    )

    const links = renderLinks

    return (
        <>
            <Drawer
                placement="left"
                isOpen={sideBarOpen}
                onClose={closeSideBar}
            >
                <DrawerOverlay />
                <DrawerContent>{links}</DrawerContent>
            </Drawer>
            <div className="bg-white shadow-lg h-full">{links}</div>
        </>
    )
}
