const authTokenKey = 'com.emddi.airport.authToken'
const authKey = 'com.emddi.airport.auth'

export const getLocalStorageAuthToken = () =>
    localStorage.getItem(authTokenKey) || ''

export const setLocalStorageAuth = auth => {
    if (!auth) return localStorage.clear()

    const { office, user, token } = auth
    localStorage.setItem(authKey, JSON.stringify({ office, user, token }))
    localStorage.setItem(authTokenKey, token)
}

export const getLocalStorageAuth = () => {
    const json = localStorage.getItem(authKey)
    if (!json) return null

    const { office, user, token } = JSON.parse(json)
    return { office, user, token }
}
