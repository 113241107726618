import { useAuthReducer } from 'context/AuthContext/AuthReducer'
import { createContext, useContext } from 'react'
import { getLocalStorageAuth } from 'services/localStorage/authStorage'

export const defaultAuthContextState = {
    user: {
        id: 0,
    },
}

export const AuthContext = createContext({
    dispatch: () => null,
    state: defaultAuthContextState,
})

export const AuthContextProvider = props => {
    const auth = getLocalStorageAuth()

    const { state, dispatch } = useAuthReducer(auth?.token ? auth : {})

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const { state, dispatch } = useContext(AuthContext)

    return { state, dispatch }
}
