import { TripReducerTypes } from 'context/TripContext/TripReducer'
import { EmddiService } from 'services/EmddiService/EmddiService'

export const getTripAction = async form => {
    const { limit, page, status, tripCode } = form

    const query = {
        limit,
        page,
        tripCode: tripCode.trim(),
        status: Object.keys(status)
            .filter(k => !!status[k])
            .join(','),
    }

    const { success, data, message } = await EmddiService.getTrip(query)

    if (!success) return { error: message }

    const { trips } = data
    if (!trips?.rows) return { error: 'Có lỗi xảy ra' }

    return {
        type: TripReducerTypes.setTrip,
        payload: { trips: { total: trips.count, list: trips.rows } },
    }
}
