import { Button as ChakraButton } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const Button = forwardRef(
    ({ className, color, loading, ...props }, ref) => {
        return (
            <ChakraButton
                colorScheme={color || 'teal'}
                className={className || ''}
                isLoading={!!loading}
                ref={ref}
                {...props}
            >
                {props.children}
            </ChakraButton>
        )
    }
)
