import { Nav } from 'components/App/Nav'
import { SideBar } from 'components/App/SideBar'
import { userRole } from 'consts/userRole'
import { useUserRole } from 'hooks/useUserRole'
import { Navigate, Route, Routes } from 'react-router'
import { NotFoundScreen } from 'screens/404/NotFoundScreen'
import { LoginScreen } from 'screens/Login/LoginScreen'
import { ServiceListScreen } from 'screens/ServiceList/ServiceListScreen'
import { TripScreen } from 'screens/Trip/TripScreen'
import { useState } from 'react'

const guestRoutes = [
    {
        element: <LoginScreen />,
        url: '/',
    },
]

const adminRoutes = [
    {
        element: <TripScreen />,
        url: '/trip/:status',
    },
    {
        element: <ServiceListScreen />,
        url: '/service',
        emddiRoute: true,
    },
    {
        element: <Navigate to="/trip/available" replace />,
        url: '/',
    },
]

export const App = () => {
    const role = useUserRole()
    const isEmddiUser = role === userRole.emddiAdmin
    const [sideBarOpen, setSideBarOpen] = useState(false)

    const renderRoutes = routes =>
        routes.map((route, i) =>
            route.emddiRoute ? (
                !!isEmddiUser && (
                    <Route key={i} path={route.url} element={route.element} />
                )
            ) : (
                <Route key={i} path={route.url} element={route.element} />
            )
        )

    const adminRouter = renderRoutes(adminRoutes)

    const guestRouter = renderRoutes(guestRoutes)

    const router = !!role ? adminRouter : guestRouter

    return (
        <div className="min-h-screen bg-slate-100">
            <Nav setSideBarOpen={setSideBarOpen} />
            <div className="flex h-[calc(100vh-4rem)]">
                <div className="hidden md:block md:w-[10rem]">
                    <SideBar
                        setSideBarOpen={setSideBarOpen}
                        sideBarOpen={sideBarOpen}
                    />
                </div>
                <div className="p-4 w-full md:w-[calc(100%-6rem)] h-full overflow-auto">
                    <Routes>
                        {router}
                        <Route path="*" element={<NotFoundScreen />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
