import { AuthReducerTypes } from 'context/AuthContext/AuthReducer'
import { EmddiService } from 'services/EmddiService/EmddiService'
import { setLocalStorageAuth } from 'services/localStorage/authStorage'

const clearAuth = () => {
    setLocalStorageAuth(null)

    return {
        type: AuthReducerTypes.setAuth,
        payload: { auth: null },
    }
}

const doLogin = async ({ username, password }) =>
    EmddiService.login({ username, password })

const setAuth = auth => {
    setLocalStorageAuth(auth)

    return {
        type: AuthReducerTypes.setAuth,
        payload: { auth },
    }
}

export const setAuthAction = async ({
    form: { username, password },
    logout,
}) => {
    if (logout) return clearAuth()

    const { success, data, message } = await doLogin({ username, password })
    if (!success) return { error: message }

    return setAuth(data)
}
