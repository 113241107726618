export const doReducer = (state, action, types) => {
    if (!action.type || !action.payload) return state

    const reducer =
        action.type?.reducer ||
        Object.values(types).find(item => item.key === action.type)

    if (!reducer) return state

    try {
        const updated = reducer(state, action.payload) || {}

        return { ...state, ...updated }
    } catch (e) {
        console.error(e)

        return state
    }
}
