import { useToast } from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { setAuthAction } from 'context/AuthContext/setAuth/setAuth.action'
import { showToast, toastStatus } from 'libs/toast/toast'
import { useRef, useState } from 'react'

export const LoginForm = () => {
    const input = {
        username: useRef(),
        password: useRef(),
    }
    const toast = useToast()
    const { dispatch } = useAuthContext()

    const [loading, setLoading] = useState(false)

    const onSubmit = async e => {
        e.preventDefault()
        if (loading) return

        const form = {
            username: input.username.current.value,
            password: input.password.current.value,
        }

        if (!form.username || !form.password) return

        setLoading(true)
        const auth = await setAuthAction({ form })
        setLoading(false)

        if (auth.error)
            return showToast(toast, {
                status: toastStatus.error,
                title: auth.error,
            })

        dispatch(auth)
    }

    return (
        <form className="" onSubmit={onSubmit} disabled={loading}>
            <Input
                label="Tên đăng nhập"
                id="emddi-username"
                name="username"
                inputRef={input.username}
            />
            <Input
                label="Mật khẩu"
                id="emddi-password"
                name="password"
                containerClassName="mt-2"
                type="password"
                inputRef={input.password}
            />
            <Button type="submit" className="mt-4 text-sm" loading={loading}>
                Đăng nhập
            </Button>
        </form>
    )
}
