export const setTripReducer = (_state, payload) => {
    const { trips } = payload

    if (!Array.isArray(trips?.list)) return {}

    return {
        trips: {
            list: trips.list,
            total: trips.total,
        },
    }
}
