import React from 'react'
import ReactDOM from 'react-dom/client'
import './scss/main.scss'
import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'

import { AuthContextProvider } from 'context/AuthContext/AuthContext'
import { App } from 'components/App/App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// FOR BUILD PROCESS
import { config } from 'consts/config'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
root.render(
    <BrowserRouter>
        <ChakraProvider>
            <AuthContextProvider>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </AuthContextProvider>
        </ChakraProvider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.info)
