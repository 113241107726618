import { Divider } from '@chakra-ui/react'
import classNames from 'classnames'
import { Input } from 'components/Input/Input'

const inputs = [
    {
        label: 'Số điện thoại tài xế',
        id: 'phone',
    },
    {
        label: 'Tên',
        id: 'name',
    },
    {
        label: 'Biển số xe',
        id: 'licensePlate',
    },
    {
        label: 'Hãng xe',
        id: 'manufacturer',
    },
    {
        label: 'Dòng xe',
        id: 'model',
    },
    {
        label: 'Giá HTX',
        id: 'originalFare',
    },
]

export const DispatchModalForm = ({ form, setForm, actionOriginlFare }) => {
    const onChange = ({ target: { name, value } }) => setForm({ [name]: value })

    const handleOriginalFale = e => {
        actionOriginlFare.setOriginalFare(e.target.value)
    }
    return (
        <div>
            <Input
                label="Số điện thoại tài xế"
                value={form.phone}
                onChange={onChange}
                name="phone"
            />
            {form.name !== null && form.name !== undefined && (
                <Input
                    label="Tên tài xế"
                    value={form.name}
                    onChange={onChange}
                    name="name"
                />
            )}
            <div className="pt-4 pb-2">
                <Divider />
            </div>
            <Input
                label="Biển số xe"
                value={form.licensePlate}
                onChange={onChange}
                name="licensePlate"
            />
            {form.color !== null && form.color !== undefined && (
                <Input
                    label="Màu xe"
                    value={form.color}
                    onChange={onChange}
                    name="color"
                />
            )}
            {form.manufacturer !== null && form.manufacturer !== undefined && (
                <Input
                    label="Hãng xe"
                    value={form.manufacturer}
                    onChange={onChange}
                    name="manufacturer"
                />
            )}
            {form.model !== null && form.model !== undefined && (
                <Input
                    label="Dòng xe"
                    value={form.model}
                    onChange={onChange}
                    name="model"
                />
            )}
            <Input
                type="number"
                label="Giá gốc HTX"
                value={actionOriginlFare.originalFare}
                onChange={handleOriginalFale}
                name="originalFare"
            />
        </div>
    )
}

export const DispatchModalFormI = ({ form, setForm }) => {
    const onChange = (key, value) => setForm({ [key]: value })

    const renderInput = ({ label, id, input }, i) => {
        const onChangeInput = v => onChange(id, v)

        const child = (input && input(onChangeInput)) || (
            <Input
                value={form[id]}
                label={label}
                id={id}
                onChangeText={onChangeInput}
            />
        )

        return (
            <div className={classNames({ 'mt-4': i !== 0 }, 'w-full')} key={i}>
                {child}
            </div>
        )
    }

    const formView = inputs.map(renderInput)

    return <>{formView}</>
}
