import {
    Card,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { CiMoneyBill } from 'react-icons/ci'

export const ServiceTable = ({ region, setSelectedService }) => {
    if (!Array.isArray(region?.services)) return null

    const { services } = region

    const clickServiceFare = service => setSelectedService(service)

    const renderService = service => {
        const onClick = () => clickServiceFare(service)

        return (
            <Tr key={service.id} onClick={onClick} className="cursor-pointer">
                <Td>
                    <Button
                        size="sm"
                        onClick={onClick}
                        leftIcon={<Icon as={CiMoneyBill} />}
                    >
                        Giá
                    </Button>
                </Td>
                <Td>{service.name}</Td>
                <Td>{service.class?.name}</Td>
                <Td>{service.seatQty}</Td>
            </Tr>
        )
    }

    const rows = services.map(renderService)

    const tbody = <Tbody>{rows}</Tbody>

    const thead = (
        <Thead>
            <Tr>
                <Th>Hành động</Th>
                <Th>Tên</Th>
                <Th>Loại</Th>
                <Th>Số ghế</Th>
            </Tr>
        </Thead>
    )

    return (
        <div className="bg-white rounded mb-4">
            <Card variant="outline">
                <TableContainer>
                    <Table
                        variant="striped"
                        colorScheme="teal"
                        aria-label="simple table"
                    >
                        {thead}
                        {tbody}
                    </Table>
                </TableContainer>
            </Card>
        </div>
    )
}
