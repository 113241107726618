import { Link } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import { useIsLogin } from 'hooks/useIsLogin'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { setAuthAction } from 'context/AuthContext/setAuth/setAuth.action'
import { Button } from '@chakra-ui/react'

const guestLinkUrls = [
    {
        url: '/',
        text: 'Đăng nhập',
    },
]

const adminLinkUrls = [
    {
        url: '/trip/available',
        text: 'Danh sách chuyến',
    },
]

export const Nav = ({ setSideBarOpen }) => {
    const isLogin = useIsLogin()
    const { dispatch } = useAuthContext()

    const renderLinks = urls =>
        urls.map((link, i) => (
            <Link to={link.url} key={i}>
                <Button colorScheme="teal" size="xs">
                    {link.text}
                </Button>
            </Link>
        ))

    const toggleSideBar = () => setSideBarOpen(f => !f)

    const clickLogin = async () =>
        dispatch(await setAuthAction({ logout: true, form: {} }))

    const adminLinks = renderLinks(adminLinkUrls)

    const guestLinks = renderLinks(guestLinkUrls)

    const links = isLogin ? adminLinks : guestLinks

    const logoutButton = isLogin ? (
        <Button colorScheme="teal" onClick={clickLogin}>
            Đăng xuất
        </Button>
    ) : null

    return (
        <div className="px-4 py-2 bg-app-500 shadow-lg w-full flex items-center justify-between">
            <div className="flex items-center">
                <button
                    className="p-2 rounded-full mr-2 block md:hidden"
                    onClick={toggleSideBar}
                >
                    <AiOutlineMenu size="1.2rem" color="white" />
                </button>
                <Link to="/">
                    <span className="flex items-center" href="EMDDI">
                        <img
                            src="/emddi.png"
                            className="h-8 md:h-12"
                            alt="EMDDI"
                        />
                        <span className="font-bold text-white ml-2">
                            Đặt xe taxi sân bay
                        </span>
                    </span>
                </Link>
                <div className="ml-4 hidden md:block">{links}</div>
            </div>
            {logoutButton}
        </div>
    )
}
