import {
    initTripStateCase,
    TripContextProvider,
} from 'context/TripContext/TripContext'
import { useParams } from 'react-router'
import { Trips } from 'screens/Trip/Trips'

export const TripScreen = () => {
    const params = useParams()

    const initQuery =
        params.status !== 'available'
            ? initTripStateCase.own
            : initTripStateCase.available

    return (
        <TripContextProvider defaultStateQuery={initQuery}>
            <Trips />
        </TripContextProvider>
    )
}
