import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useBreakpointValue,
    Icon,
    ModalCloseButton,
    useToast,
    InputGroup,
    InputRightElement,
    Input,
} from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { showToast } from 'libs/toast/toast'
import { useEffect, useState } from 'react'
import { MdOutlineSave } from 'react-icons/md'
import { EmddiService } from 'services/EmddiService/EmddiService'

export const FareModal = ({ service, region, setSelectedService }) => {
    const size = useBreakpointValue({
        base: 'full',
        md: 'xl',
    })
    const toast = useToast()
    const [currentService, setCurrentSerivce] = useState(null)
    const [fare, setFare] = useState(null)

    useEffect(() => {
        if (!service?.id) return

        const fetchService = async () => {
            const { success, data, message } = await EmddiService.getOneService(
                service.id
            )

            if (!success || !data?.service)
                return showToast(toast, { title: message })

            const { fare } = data?.service

            setFare(fare)
            setCurrentSerivce(data?.service)
        }

        fetchService()
    }, [service?.id])

    const close = () => setSelectedService(null)
    const save = () => {}

    const onChange = ({ target: { name, value } }) =>
        setFare(f => ({ ...f, [name]: value }))

    return (
        <Modal size={size} isOpen={!!service?.id} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{service?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <div className="mb-1">
                        Hạng:{' '}
                        <span className="text-app-500 font-bold">
                            {service?.class?.name}
                        </span>
                    </div>
                    <div className="mb-4">
                        Vùng:{' '}
                        <span className="text-app-500 font-bold">
                            {region?.name}
                        </span>
                    </div>
                    <label htmlFor="service_opening_price">Giá mở cửa</label>
                    <InputGroup>
                        <Input
                            placeholder="Giá mở cửa"
                            id="service_opening_price"
                            value={fare?.openingPrice || ''}
                            focusBorderColor="teal.500"
                            name="openingPrice"
                            onChange={onChange}
                        />
                        <InputRightElement>
                            <span className="text-app-500 text-xs">đ</span>
                        </InputRightElement>
                    </InputGroup>
                    <div className="mt-4" />
                    <label htmlFor="service_opening_distance">
                        Quãng đường mở cửa
                    </label>
                    <InputGroup>
                        <Input
                            placeholder="Quãng đường mở cửa (km)"
                            id="service_opening_distance"
                            focusBorderColor="teal.500"
                            value={fare?.openingDistance || ''}
                            name="openingDistance"
                            onChange={onChange}
                        />
                        <InputRightElement>
                            <span className="text-app-500 text-xs">km</span>
                        </InputRightElement>
                    </InputGroup>
                    <div className="mt-4" />
                    <label htmlFor="service_price_by_km">Giá/km</label>
                    <InputGroup>
                        <Input
                            placeholder="Giá/km"
                            value={fare?.priceByKm || ''}
                            focusBorderColor="teal.500"
                            id="service_price_by_km"
                            name="priceByKm"
                            onChange={onChange}
                        />
                        <InputRightElement>
                            <span className="text-app-500 text-xs">đ/km</span>
                        </InputRightElement>
                    </InputGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={close} variant="outline" className="mr-2">
                        Hủy
                    </Button>
                    <Button
                        leftIcon={<Icon as={MdOutlineSave} />}
                        onClick={save}
                    >
                        Sửa giá {service?.name}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
