import { Input } from 'components/Input/Input'
import { useTripContext } from 'context/TripContext/TripContext'
import { tripActions } from 'context/TripContext/TripReducer'
import { Card, CardBody } from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { useEffect, useState } from 'react'

export const Filter = ({ submit }) => {
    const { state, dispatch } = useTripContext()
    const [rendered, setRendered] = useState(false)

    useEffect(() => {
        setRendered(true)
    }, [])

    useEffect(() => {
        const delaySearch = setTimeout(async () => {
            if (rendered) return submit()
        }, 350)

        return () => clearTimeout(delaySearch)
    }, [state?.form.tripCode])

    const onChangeInputId = tripCode =>
        dispatch(tripActions.editInput({ tripCode }))

    const onSubmit = e => {
        e.preventDefault()
        submit()
    }

    return (
        <div className="bg-white rounded mb-4">
            <Card variant="outline">
                <CardBody>
                    <form onSubmit={onSubmit}>
                        <Input
                            value={state?.form?.tripCode || ''}
                            label="Mã chuyến"
                            id="tripCode"
                            onChangeText={onChangeInputId}
                        />
                        <div className="flex justify-center mt-4">
                            <Button color="gray" type="submit">
                                Tìm kiếm
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    )
}
