import { usePagination } from '@emddi/chakra-ui-utils'
import { tripStatus } from 'consts/tripStatus'
import { useTripReducer } from 'context/TripContext/TripReducer'
import { createContext, useContext, useEffect, useState } from 'react'

let pageCurent = 0

export const defaultTripState = {
    form: {
        status: Object.values(tripStatus).reduce((result, key) => ({
            ...result,
            [key]: false,
        })),
        tripCode: '',
        limit: 10,
        page: pageCurent,
    },
    trips: {
        list: [],
        total: 0,
    },
}

export const TripContext = createContext({
    dispatch: () => null,
    state: defaultTripState,
})

export const useTripContext = () => {
    const { state, dispatch, getInfoPaging } = useContext(TripContext)

    return { state, dispatch, getInfoPaging }
}

export const initTripStateCase = {
    available: 'trip_state_case:available',
    own: 'trip_state_case:own',
}

export const TripContextProvider = ({ children, defaultStateQuery }) => {
    const [totalCardList, SetTotalCardList] = useState(pageCurent)

    const { limit, page, setCurrentPage, setLimit, totalPages } = usePagination(
        { total: totalCardList }
    )

    const getInfoPaging = { limit, page, setCurrentPage, setLimit, totalPages }

    const defaultStatusForm =
        defaultStateQuery === initTripStateCase.available
            ? {
                  status: { [tripStatus.created.value]: true },
              }
            : {
                  status: [
                      tripStatus.coming,
                      tripStatus.onTrip,
                      tripStatus.finished,
                  ].reduce((r, k) => ({ ...r, [k.value]: true }), {}),
              }

    const { state, dispatch } = useTripReducer({
        ...defaultTripState,
        form: {
            ...defaultTripState.form,
            ...defaultStatusForm,
        },
    })

    useEffect(() => {
        SetTotalCardList(state?.trips?.total)
        pageCurent = page
        // console.log(defaultTripState.form.page, pageCurent)
    }, [limit, page, state])

    return (
        <TripContext.Provider value={{ state, dispatch, getInfoPaging }}>
            {children}
        </TripContext.Provider>
    )
}
