import { Text as ChakraText } from '@chakra-ui/react'

export const Text = props => {
    const { children, color, ...textProps } = props

    return (
        <ChakraText color={color || 'teal'} {...textProps}>
            {children}
        </ChakraText>
    )
}
