import axios from 'axios'
import { config } from 'consts/config'
import { getLocalStorageAuthToken } from 'services/localStorage/authStorage'

export const handleEmddiApi =
    ({ method, url }) =>
    async input => {
        const fullUrl = `${config.emddiService.host}${url}`
        const authToken = getLocalStorageAuthToken()

        const request = {
            method,
            url: fullUrl,
            ...(method.toLowerCase() === 'get'
                ? { params: input }
                : { data: input }),
            headers: {
                authorization: authToken ? `Bearer ${authToken}` : '',
            },
        }

        try {
            const { data: response } = await axios(request)

            return {
                success: !!response?.success,
                data: response.data,
                message: response.message,
                code: response.code,
            }
        } catch (e) {
            return {
                success: false,
                data: null,
                message: e.message || e,
                code: 0,
            }
        }
    }
