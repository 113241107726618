export const tripStatus = {
    created: { text: 'Đang tìm tài xế', value: 'CREATED' },
    coming: { text: 'Đã nhận chuyến', value: 'COMING' },
    onTrip: { text: 'Đã trong chuyến', value: 'ON_TRIP' },
    finished: { text: 'Hoàn thành', value: 'FINISHED' },
    driverCancelled: { text: 'Tài xế huỷ', value: 'DRIVER_CANCELLED' },
    customerCancelled: { text: 'Khách huỷ', value: 'CUSTOMER_CANCELLED' },
    operatorCancelled: { text: 'Điều hành huỷ', value: 'OPERATOR_CANCELLED' },
    timeout: { text: 'Trôi', value: 'TIME_OUT' },
}
