import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useBreakpointValue,
    Icon,
    ModalCloseButton,
    useToast,
} from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { useState } from 'react'
import { DispatchModalForm } from 'screens/Trip/DispatchModalForm'
import { MdOutlineLocalTaxi } from 'react-icons/md'
import { DispatchTripInfo } from 'screens/Trip/DispatchTripInfo'
import { EmddiService } from 'services/EmddiService/EmddiService'
import { showToast, toastStatus } from 'libs/toast/toast'
import { useNavigate } from 'react-router'

export const DispatchModal = ({ trip, onClose }) => {
    const size = useBreakpointValue({
        base: 'full',
        md: 'xl',
    })
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [form, setForm] = useState({
        phone: '',
        name: '',
        licensePlate: '',
        manufacturer: '',
        model: '',
        color: '',
    })

    const close = () => {
        onClose()
    }

    const changeForm = update => setForm(f => ({ ...f, ...update }))

    const submit = async () => {
        if (loading) return

        setLoading(true)
        const { success, message } = await EmddiService.dispatchTrip({
            tripId: trip?.id,
            driver: form,
        })
        setLoading(false)

        if (!success) return showToast(toast, { title: message })

        showToast(toast, {
            status: toastStatus.success,
            title: 'Nhận chuyến thành công',
        })

        window.location.reload()
    }

    return (
        <Modal size={size} isOpen={!!trip?.id} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Xác nhận điều chuyến?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <DispatchModalForm form={form} setForm={changeForm} />
                    <DispatchTripInfo trip={trip} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={close} variant="outline" className="mr-2">
                        Hủy
                    </Button>
                    <Button
                        loading={loading}
                        leftIcon={<Icon as={MdOutlineLocalTaxi} />}
                        onClick={submit}
                    >
                        Nhận chuyến
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
