import { defaultAuthContextState } from 'context/AuthContext/AuthContext'
import { setAuthReducer } from 'context/AuthContext/setAuth/setAuth.reducer'
import { useReducer } from 'react'
import { doReducer } from 'services/reducer/reducer'

export const AuthReducerTypes = {
    setAuth: { key: 'SET_AUTH', reducer: setAuthReducer },
}

export const AuthReducer = (state, action) =>
    doReducer(state, action, AuthReducerTypes)

export const useAuthReducer = initState => {
    const [state, dispatch] = useReducer(AuthReducer, {
        ...defaultAuthContextState,
        ...(initState || {}),
    })

    return { state, dispatch }
}
