import { Card, CardBody, Select } from '@chakra-ui/react'

export const RegionFilter = ({ regions }) => {
    const renderRegionInput = (region, id) => (
        <option value={region.id} key={id}>
            {region.name}
        </option>
    )

    const regionInput = regions.map(renderRegionInput)

    return (
        <div className="bg-white rounded mb-4">
            <Card variant="outline">
                <CardBody>
                    <div className="mb-2">
                        <label>Vùng</label>
                    </div>
                    <Select variant="filled">{regionInput}</Select>
                </CardBody>
            </Card>
        </div>
    )
}
