import { Helmet } from 'react-helmet'
import { ServiceList } from 'screens/ServiceList/ServiceList'

export const ServiceListScreen = props => {
    return (
        <div>
            <Helmet title="Dịch vụ" />
            <ServiceList {...props} />
        </div>
    )
}
