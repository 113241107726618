import { TripContext, useTripContext } from 'context/TripContext/TripContext'
import dayjs from 'dayjs'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Card,
    Icon,
    Tooltip,
    ButtonGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverFooter,
    useToast,
} from '@chakra-ui/react'
import { Button } from 'components/Button/Button'
import { tripStatusToText } from 'libs/tripStatus/tripStatusToText'

import { MdInfoOutline, MdClear, MdOutlineLocationOn } from 'react-icons/md'
import { RxLightningBolt } from 'react-icons/rx'
import { tripStatus } from 'consts/tripStatus'
import { tripActionType } from 'consts/tripActionType'
import { useContext, useEffect, useState } from 'react'
import { EmddiService } from 'services/EmddiService/EmddiService'
import { showToast, toastStatus } from 'libs/toast/toast'
import { useUserRole } from 'hooks/useUserRole'
import { userRole } from 'consts/userRole'
import { Pagination, usePagination } from '@emddi/chakra-ui-utils'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { getLocalStorageAuthToken } from 'services/localStorage/authStorage'

export const TripTable = ({
    isAvailableQuery,
    refetch,
    openTrip,
    dispatchTrip: propsDispatchTrip,
}) => {
    const { state } = useTripContext()

    const { getInfoPaging } = useContext(TripContext)
    const [loading, setLoading] = useState(false)
    const toast = useToast()

    const dispatchTrip = trip => propsDispatchTrip(trip)

    const role = useUserRole()

    const [listTripData, setListTrip] = useState([])
    const { limit, page, setCurrentPage, setLimit, totalPages } = usePagination(
        { total: state?.trips?.total }
    )

    const listTrip = useQuery(
        ['listCard'],
        async () => {
            const authToken = getLocalStorageAuthToken()
            const { data } = await axios.get(
                `${
                    process.env.REACT_APP_EMDDI_HOST
                }/office-user/trip?limit=${limit}&page=${
                    page - 1
                }&status=${Object.keys(state.form.status)
                    .filter(k => !!state.form.status[k])
                    .join(',')}&tripCode=${state.form.tripCode}`,
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )

            return data
        },
        {
            onSuccess: data => {
                setListTrip(data?.data.trips.rows)
            },
        }
    )

    useEffect(() => {
        listTrip.refetch()
    }, [limit, page])

    const fastDispatchTrip = async trip => {
        if (loading) return

        setLoading(true)
        const { success, message } = await EmddiService.dispatchTrip({
            tripId: trip.id,
            driver: {},
        })
        setLoading(false)

        showToast(toast, {
            status: success ? toastStatus.success : toastStatus.error,
            title: success ? 'Nhận chuyến thành công' : message,
        })

        // window.location.reload()
    }

    const updateStatus = async (trip, status) => {
        if (loading) return

        const { success, data, message } = await EmddiService.updateTripStatus({
            tripId: trip.id,
            status: status,
        })

        if (!success) return showToast(toast, { title: message })

        showToast(toast, {
            status: toastStatus.info,
            title:
                status === tripActionType.finish
                    ? 'Đã kết thúc chuyến'
                    : 'Huỷ chuyến thành công',
        })

        return refetch()
    }

    const renderWaypoint = waypoint => {
        const name = (
            <div className="flex">
                <div className="">{waypoint.name}</div>
            </div>
        )
        const address = <div className="text-xs">{waypoint.address}</div>

        return (
            <div className="flex flex-col py-1">
                {name}
                {address}
            </div>
        )
    }

    const renderTrip = trip => {
        const hideExternalAdmin = role === userRole.externalAdmin

        const pickupTime = dayjs(trip.pickupTime)
        const clickCancel = () => updateStatus(trip, tripActionType.cancel)
        const clickFinish = () => updateStatus(trip, tripActionType.finish)
        const clickFastDispatch = () => fastDispatchTrip(trip)

        return (
            <Tr key={trip.id} className="hover:bg-slate-200">
                <Td>
                    {isAvailableQuery ? (
                        !hideExternalAdmin && (
                            <div className="flex flex-col">
                                <Button
                                    leftIcon={<Icon as={RxLightningBolt} />}
                                    onClick={clickFastDispatch}
                                >
                                    Nhận nhanh
                                </Button>
                            </div>
                        )
                    ) : (
                        <>
                            <div>
                                <Button
                                    className="w-32"
                                    variant="outline"
                                    onClick={() => openTrip(trip)}
                                    leftIcon={<Icon as={MdInfoOutline} />}
                                >
                                    <span className="ml-2">Chi tiết</span>
                                </Button>
                            </div>
                            {trip?.status === tripStatus.coming.value &&
                                !hideExternalAdmin && (
                                    <div className="mt-1">
                                        <ButtonGroup isAttached>
                                            <Tooltip label="Đánh dấu tài xế đã hoàn thành chuyến">
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button className="w-16">
                                                            <Icon
                                                                as={
                                                                    MdOutlineLocationOn
                                                                }
                                                            />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverBody>
                                                            <div className="text-center">
                                                                Xác nhận hoàn
                                                                thành chuyến?
                                                            </div>
                                                        </PopoverBody>
                                                        <PopoverFooter>
                                                            <div className="flex justify-center">
                                                                <Button
                                                                    onClick={
                                                                        clickFinish
                                                                    }
                                                                >
                                                                    Hoàn thành
                                                                </Button>
                                                            </div>
                                                        </PopoverFooter>
                                                    </PopoverContent>
                                                </Popover>
                                            </Tooltip>
                                            <Tooltip label="Huỷ chuyến">
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button
                                                            className="w-16"
                                                            color="red"
                                                        >
                                                            <Icon
                                                                as={MdClear}
                                                            />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverBody>
                                                            <div className="text-center">
                                                                Xác nhận huỷ
                                                                chuyến?
                                                            </div>
                                                        </PopoverBody>
                                                        <PopoverFooter>
                                                            <div className="flex justify-center">
                                                                <Button
                                                                    color="red"
                                                                    onClick={
                                                                        clickCancel
                                                                    }
                                                                >
                                                                    Huỷ chuyến
                                                                </Button>
                                                            </div>
                                                        </PopoverFooter>
                                                    </PopoverContent>
                                                </Popover>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </div>
                                )}
                        </>
                    )}
                </Td>
                <Td>
                    <div>{pickupTime.format('HH:mm DD/MM/YY')}</div>
                    <div>KH: {trip.name}</div>
                    <div className="text-sm">
                        {trip.phone.replace('+84', '0')}
                    </div>
                    <div className="text-sm">{trip.email}</div>
                </Td>
                <Td>
                    <>
                        <div className="font-bold text-xs font-mono text-app-800">
                            {trip.code.toUpperCase()}{' '}
                            {!!trip?.flightCode && (
                                <div>{`Mã chuyến bay: ${trip?.flightCode}`}</div>
                            )}
                        </div>
                        <div>{tripStatusToText(trip.status)}</div>
                        {!!trip?.operator?.id && (
                            <div className="text-sm">
                                Điều hành bởi:{' '}
                                <span className="font-bold">
                                    {trip.operator.name ||
                                        trip.operator.username}{' '}
                                    - {trip.office?.name}
                                </span>
                            </div>
                        )}
                        <div className="text-sm">
                            Đặt lúc:{' '}
                            {dayjs(trip.createdAt).format('HH:mm DD/MM/YY')}
                        </div>
                    </>
                </Td>
                <Td>
                    <div>{renderWaypoint(trip?.waypoints?.[0])}</div>
                    <div>{renderWaypoint(trip?.waypoints?.[1])}</div>
                    <div>
                        {trip?.service?.name} - Khách trả:{' '}
                        <span className="font-bold text-app-700">
                            {Number(trip.price - trip.discount).toLocaleString(
                                'vi-vn'
                            )}{' '}
                            VNĐ
                        </span>
                    </div>
                    {!!trip.discount && (
                        <div className="text-xs mt-2">
                            <div className="text-app-700">
                                Cước phí:
                                {Number(trip.price).toLocaleString('vi-vn')} VNĐ
                            </div>
                            <div className="text-red-700">
                                KM:
                                {Number(trip.discount).toLocaleString(
                                    'vi-vn'
                                )}{' '}
                                VNĐ
                            </div>
                        </div>
                    )}
                </Td>
            </Tr>
        )
    }

    const _trips = state?.trips?.list

    const rows =
        Array.isArray(listTripData) && listTripData.length ? (
            listTripData.map(renderTrip)
        ) : (
            <div className="p-4 flex italic text-xs">Không có dữ liệu</div>
        )

    return (
        <div className="bg-white rounded mb-4">
            <Card variant="outline">
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Thead>
                            <Tr>
                                <Th>Hành động</Th>
                                <Th>Khởi hành</Th>
                                <Th>Mã chuyến - Trạng thái</Th>
                                <Th>Địa điểm</Th>
                            </Tr>
                        </Thead>
                        <Tbody>{rows}</Tbody>
                    </Table>
                </TableContainer>
            </Card>
            <Pagination
                pageCount={totalPages}
                currentPage={page}
                onPageChange={setCurrentPage}
                limit={limit}
                onLimitChange={limit => {
                    setLimit(limit)
                    setCurrentPage(0)
                }}
            />
        </div>
    )
}
