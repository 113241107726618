import { useToast } from '@chakra-ui/react'
import { Heading } from 'components/Heading/Heading'
import { showToast } from 'libs/toast/toast'
import { useEffect, useState } from 'react'
import { FareModal } from 'screens/ServiceList/FareModal'
import { EmddiService } from 'services/EmddiService/EmddiService'
import { RegionFilter } from './RegionFilter'
import { ServiceTable } from './ServiceTable'

export const ServiceList = () => {
    const toast = useToast()
    const [regions, setRegions] = useState([])
    const [selectedRegionId] = useState(0)
    const [selectedService, setSelectedService] = useState(null)

    useEffect(() => {
        const fetchServices = async () => {
            const { success, data, message } =
                await EmddiService.getListService()

            if (!success || !Array.isArray(data?.regions))
                return showToast(toast, { title: message || 'Có lỗi xảy ra' })

            setRegions(data.regions)
        }

        fetchServices()
    }, [])

    const selectedRegion = regions?.[selectedRegionId]

    return (
        <div>
            <Heading title="Dịch vụ" />
            <RegionFilter regions={regions} />
            <ServiceTable
                setSelectedService={setSelectedService}
                region={selectedRegion}
            />
            <FareModal
                service={selectedService}
                region={selectedRegion}
                setSelectedService={setSelectedService}
            />
        </div>
    )
}
