import { TripReducerTypes } from 'context/TripContext/TripReducer'

export const editInputAction = input => {
    if (!input) return

    return {
        type: TripReducerTypes.updateInput,
        payload: { input },
    }
}
