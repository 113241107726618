import { Button } from 'components/Button/Button'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export const NotFoundScreen = () => {
    return (
        <>
            <Helmet title="EMDDI Sân bay" />
            <div className="p-8 flex flex-col justify-center items-center">
                <div className="text-app-900 mb-8">Không tìm thấy trang</div>
                <Link to="/">
                    <Button>Về trang chủ</Button>
                </Link>
            </div>
        </>
    )
}
