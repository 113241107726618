export const editInputReducer = (state, payload) => {
    const { input } = payload

    return {
        form: {
            ...state.form,
            ...input,
        },
    }
}
