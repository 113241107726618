import { useToast, Skeleton, Stack, Flex } from '@chakra-ui/react'
import { TripContext, useTripContext } from 'context/TripContext/TripContext'
import { tripActions } from 'context/TripContext/TripReducer'
import { showToast } from 'libs/toast/toast'
import { useContext, useEffect, useRef, useState } from 'react'
import { DispatchModal } from 'screens/Trip/DispatchModal'
import { Filter } from 'screens/Trip/Filter'
import { TripTable } from 'screens/Trip/TripTable'
import { Button } from 'components/Button/Button'
import { config } from 'consts/config'
import { getLocalStorageAuthToken } from 'services/localStorage/authStorage'
import { useParams } from 'react-router'
import { tripStatus } from 'consts/tripStatus'
import { Heading } from 'components/Heading/Heading'
import { Helmet } from 'react-helmet'
import { TripInfoModal } from 'screens/Trip/TripInfoModal'
import { useUserRole } from 'hooks/useUserRole'
import { userRole } from 'consts/userRole'
import { RangeDatepicker } from 'chakra-dayzed-datepicker'
import dayjs from 'dayjs'

export const Trips = () => {
    const role = useUserRole()
    const { state, dispatch } = useTripContext()
    const [dispatchingTrip, setDispatchingTrip] = useState(null)
    const [fetching, setFetching] = useState(false)
    const toast = useToast()
    const toastRef = useRef(toast)
    const fetchingRef = useRef(fetching)
    const params = useParams()
    const isAvailableQuery = params.status === 'available'
    const [showTrip, setShowTrip] = useState(null)

    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()])
    const { getInfoPaging } = useContext(TripContext)

    useEffect(() => {
        setStartTime(dayjs(selectedDates[0]).valueOf())
        setEndTime(dayjs(selectedDates[1]).valueOf())

        const status = isAvailableQuery
            ? { [tripStatus.created.value]: true }
            : [
                  tripStatus.coming,
                  tripStatus.onTrip,
                  tripStatus.finished,
              ].reduce((r, k) => ({ ...r, [k.value]: true }), {})

        dispatch(tripActions.editInput({ status }))
    }, [isAvailableQuery, selectedDates])

    const fetchTrips = async () => {
        if (fetchingRef.current) return

        setFetching(true)

        const response = await tripActions.getTrip(state.form)
        setFetching(false)

        if (response.error)
            return showToast(toastRef.current, {
                description: response.error,
            })

        dispatch && dispatch(response)
    }

    useEffect(() => {
        fetchTrips()
    }, [state.form.status, getInfoPaging.page])

    const dispatchTrip = trip => setDispatchingTrip(trip)
    const cancelDispatching = () => {
        setDispatchingTrip(null)
    }
    const refetch = () => fetchTrips()

    const doExport = () => {
        const token = getLocalStorageAuthToken()
        window.open(
            `${config.emddiService.host}/office-user/trip/export?token=${token}&endTime=${endTime}&startTime=${startTime}`,
            'noopener,noreferrer'
        )
    }
    return (
        <div>
            <Helmet
                title={
                    params.status === 'available'
                        ? 'Chuyến khả dụng'
                        : 'Chuyến đã nhận'
                }
            />
            <Heading
                title={
                    params.status === 'available'
                        ? 'Chuyến khả dụng'
                        : 'Chuyến đã nhận'
                }
            />
            {!isAvailableQuery && role !== userRole.officeAdmin && (
                <div>
                    <Heading title="Xuất danh sách chuyến đi" />
                    <Flex gap={6} className="mb-2">
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                        />
                        <Button onClick={doExport}>Export</Button>
                    </Flex>
                </div>
            )}
            <Filter submit={refetch} />
            {fetching ? (
                <Stack>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                </Stack>
            ) : (
                <TripTable
                    dispatchTrip={dispatchTrip}
                    isAvailableQuery={isAvailableQuery}
                    refetch={fetchTrips}
                    openTrip={setShowTrip}
                />
            )}
            <DispatchModal trip={dispatchingTrip} onClose={cancelDispatching} />
            <TripInfoModal trip={showTrip} close={() => setShowTrip(null)} />
        </div>
    )
}
