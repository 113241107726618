import { handleEmddiApi } from 'services/EmddiService/handleEmddiApi'

const login = ({ username, password }) =>
    handleEmddiApi({ method: 'post', url: '/office-user/auth/login' })({
        username,
        password,
    })

const getTrip = ({ limit, page, status, tripId, tripCode }) =>
    handleEmddiApi({ method: 'get', url: '/office-user/trip' })({
        limit,
        page,
        status,
        tripId,
        tripCode,
    })

const dispatchTrip = ({
    tripId,
    driver: { phone, name, licensePlate, manufacturer, model, color },
    originalFare,
}) =>
    handleEmddiApi({
        method: 'post',
        url: `/office-user/trip/${tripId}/dispatch`,
    })({
        tripId,
        driver: { phone, name, licensePlate, manufacturer, model, color },
        originalFare,
    })

const getOneDriver = phone =>
    handleEmddiApi({ method: 'get', url: '/office-user/driver/findOne' })({
        phone,
    })

const getOneVehicle = licensePlate =>
    handleEmddiApi({ method: 'get', url: '/office-user/vehicle/findOne' })({
        licensePlate,
    })

const getListService = () =>
    handleEmddiApi({
        method: 'get',
        url: '/office-user/service/groupByRegion',
    })({})

const getOneService = serviceId =>
    handleEmddiApi({ method: 'get', url: `/office-user/service/${serviceId}` })(
        {}
    )

const findOneDriver = phone =>
    handleEmddiApi({ method: 'get', url: '/office-user/driver/findOne' })({
        phone,
    })

const findOneVehicle = licensePlate =>
    handleEmddiApi({ method: 'get', url: '/office-user/vehicle/findOne' })({
        licensePlate,
    })

const updateTripStatus = ({ tripId, status }) =>
    handleEmddiApi({
        method: 'post',
        url: `/office-user/trip/${tripId}/status`,
    })({ status })

export const EmddiService = {
    login,
    getTrip,
    dispatchTrip,
    getOneDriver,
    getOneVehicle,
    getListService,
    getOneService,
    findOneDriver,
    findOneVehicle,
    updateTripStatus,
}
