export const setAuthReducer = (_state, payload) => {
    if (!payload?.auth?.token)
        return {
            token: '',
            user: null,
            office: null,
        }

    const { token, user, office } = payload.auth
    return {
        token,
        user,
        office,
    }
}
