import dayjs from 'dayjs'

export const DispatchTripInfo = ({ trip }) => {
    const renderWaypoint = (waypoint, i) => (
        <div key={i}>
            {i === 0 ? 'Từ: ' : 'Đến: '}
            {waypoint.name}
        </div>
    )

    const waypoints = trip.waypoints.map(renderWaypoint)

    return (
        <div className="mt-4">
            <div className="font-bold">Thông tin chuyến đi</div>
            <div className="text-sm">{waypoints}</div>
            <div className="mt-2">
                Giá: {Number(trip.price).toLocaleString('vi-vn')} VND
            </div>
            <div className="mt-2">
                Khởi hành: {dayjs(trip.pickupTime).format('HH:mm DD/MM/YY')} VND
            </div>
        </div>
    )
}
