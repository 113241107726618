export const showToast = (toast, { title, description, status, duration }) =>
    toast({
        title,
        description,
        status: status || 'error',
        duration: duration || 5000,
        isClosable: true,
    })

export const toastStatus = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
}
